<template>
  <div class="mg-product">
    <div class="main-box">
      <div class="left">
        <div class="categories">
          <div
            class="category"
            v-for="(item, index) in categories"
            :key="index"
            @mouseenter="currentIndex = index"
            :class="{ active: currentIndex === index }"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="right">
        <product-menu :title="currentName" :menus="currentMenus" @menu-click="handleMenuClick" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductMenu from '@/components/Menu';
import { cloneDeep } from 'lodash';
// import { hotProductMenus, mediaProductMenus, cloudProductMenus } from '@/maps/common';
import { getMenulist } from '@/api/menu';
// import { list } from '@/api/menu'

export default {
  name: 'MgProduct',
  components: {
    ProductMenu,
  },
  data() {
    return {
      categories: [
        {
          name: '热门产品',
          path: '/',
          menus: [],
        },
        // {
        //   name: '媒体产品',
        //   path: '/',
        //   menus: mediaProductMenus,
        // },
        // {
        //   name: '云计算基础',
        //   path: '/',
        //   menus: cloudProductMenus,
        // },
      ],
      currentIndex: 0,
      currentName: '',
      currentMenus: [],
    };
  },
  watch: {
    currentIndex(newValue, oldValue) {
      //   console.log('this.categories[newValue]=', this.categories[newValue]);
      this.currentName = this.categories[newValue].name;
      // TODO 当currentIndex变化时，需要重新获取数据
      this.currentMenus = this.categories[newValue].menus;
    },
  },
  created() {
    this.getMenulistFun();
  },
  methods: {
    listToTree(list) {
      const data = cloneDeep(list);
      const obj = {};
      data.forEach(ele => {
        obj[ele.menu_id] = ele;
      });
      const parentList = [];
      data.forEach(ele => {
        const parent = obj[ele.parentId];
        if (parent) {
          parent.children = parent.children || [];
          parent.children.push(ele);
        } else {
          parentList.push(ele);
        }
      });
      return parentList;
    },
    getMenulistFun() {
      getMenulist().then(res => {
        const { data } = res;
        if (data.length > 0) {
          const list = data.map(ele => {
            const { name, is_hot, menu_id, first_menu_id, second_menu_id } = ele;
            if (first_menu_id === 0) {
              return {
                menu_id,
                parentId: 0,
                name: name,
                is_hot,
              };
            } else if (first_menu_id !== 0 && second_menu_id === 0) {
              return {
                menu_id,
                parentId: first_menu_id,
                name: name,
                is_hot,
              };
            } else {
              return {
                menu_id,
                parentId: second_menu_id,
                name: name,
                is_hot,
              };
            }
          });
          const menus = this.listToTree(list);
          this.handleHostProduct(menus);
          menus.forEach(ele => {
            this.categories.push({
              name: ele.name,
              path: '/',
              menus: ele.children,
            });
          });
          this.categories.push({
            name: '大屏',
            path: '/',
            menus: [
              {
                name: '超算融合大屏',
                href: 'https://bigscreen.mlsvcloud.com',
              },
              {
                name: '马栏山算力地图',
                href: 'https://cp-map.mlsvcloud.com',
              },
              {
                name: '视频拆条服务',
                href: 'http://111.22.161.112:8099',
              },
            ],
          });
        }
      });
    },

    /** 过滤出热门产品 */
    filterTree(nodes) {
      return nodes.filter(node => {
        node.children = node.children ? this.filterTree(node.children) : [];
        return node.is_hot === 1 || node.children.length > 0;
      });
    },

    /** 处理热门产品菜单 */
    handleHostProduct(list) {
      console.log(list);
      const data = cloneDeep(list);
      const hotProductMenus = this.filterTree(data);
      console.log(hotProductMenus);
      hotProductMenus.forEach(ele => {
        if (ele.children && ele.children.length > 0) {
          if (ele.children[0].children && ele.children[0].children.length > 0) {
            this.categories[0].menus.push(...ele.children);
          } else {
            this.categories[0].menus.push(ele);
          }
        }
      });
      this.currentName = this.categories[0].name;
      this.currentMenus = this.categories[0].menus;
    },

    handleMenuClick(item) {
      if (item.menu_id !== undefined && item.menu_id !== null) {
        this.$router.push({
          name: 'products',
          params: {
            id: item.menu_id,
          },
        });
      } else if (item.href) {
        window.open(item.href, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mg-product {
  height: 100%;
  padding: 0 10.313vw;

  .main-box {
    display: flex;
    width: 100%;
    height: 100%;

    .left {
      box-sizing: border-box;
      width: 21.25vw;
      height: 100%;
      border-right: 1px solid #d8d8d8;

      .categories {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-top: 1.458vw;

        .category {
          width: 100%;
          height: 2.5vw;
          padding-left: 0.521vw;
          color: #999;
          font-size: 0.833vw;
          line-height: 2.5vw;
          background: #fff;
          cursor: pointer;

          &.active {
            color: #222;
            background: #eef0f5;
          }
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
    }
  }
}
</style>
